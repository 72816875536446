import React, { useEffect } from "react"
import { CSSObject, useTheme } from "@emotion/react"
import { Text } from "../ui"
import { AppTheme } from "pitch45-common/theme/app-theme.types"
import Loop45Logo from "../components/logo/logo"
import { textPresets } from "../ui/text/text.presets"
import { semanticSpacing, unitSpacing } from "../theme/spacing"
import { wrapSSR } from "../utils/wrap-ssr"
import logger from "pitch45-common/logging/logger"

function getStyles(theme: AppTheme) {
  const styles = {
    CONTAINER: {
      minHeight: "100vh",
      padding: semanticSpacing.horizontal.narrow,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      background: theme.colors.background,
    },
    LOGO: {
      width: "30vw",
      marginBottom: unitSpacing.double,
    },
    DETAILS: {
      textAlign: "center",
      marginTop: unitSpacing.unit,
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

interface ErrorPageProps {
  error: Error
}

export default function TopLevelErrorPage(props: ErrorPageProps) {
  const theme = useTheme() as AppTheme
  const styles = getStyles(theme)

  useEffect(() => {
    logger.logError(new Error("Rendered the web Yikes screen!"), { originalError: props.error })
  }, [])

  return (
    <div css={styles.CONTAINER}>
      <span css={styles.LOGO}>
        <Loop45Logo size="large" />
      </span>
      <Text preset={textPresets.largeSemibold}>Yikes!</Text>
      <Text preset={textPresets.medium} css={styles.DETAILS}>
        Please contact our support team if this error persists.
      </Text>
    </div>
  )
}

export const getServerSideProps = wrapSSR(() => {
  return {
    props: {},
  }
})
