import {
  flow,
  IStateTreeNode,
  Instance,
  getParent,
  SnapshotOut,
  toGenerator,
  types,
} from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import {
  GroupMembership,
  ReactionSearchResult,
  SearchApi,
  SearchFilterType,
  SearchRequest,
} from "../services/api/search-api"
import { withPitchStore } from "./pitch-store"
import { withPlaylistStore } from "./playlist-store"
import { withGroupStore } from "./group-store"
import { withUserStore } from "./user-store"
import { withOrganizationStore } from "./organization-store"
import { PitchWithPlaylistMetadata } from "../services"
import { Program } from "../models/program"
import { withProgramStore } from "./program-store"
import { Group } from "../models/group"
import { Pitch } from "../models/pitch"
import { Playlist } from "../models/playlist"
import { PublicUser } from "../models/public-user"
import { StudioPitch } from "../models/studio-pitch-info"
import { RootStoreModel } from "./root-store"

// Which resources do we want to request
export enum ResourceSearchType {
  Pitch,
  PlaylistPitch,
  StudioPitch,
  Playlist,
  SubmissionPlaylists,
  Group,
  User,
  Program,
  Reaction,
  GroupUser,
}

type SearchResource =
  | Pitch
  | Playlist
  | StudioPitch
  | Group
  | PublicUser
  | Program
  | ReactionSearchResult
  | GroupMembership

export interface SearchResult<T extends SearchResource> {
  results: T[]
  totalCount: number
  pageNumber: number
  numPages: number
}

export const ResourceSearchStoreModel = types
  .model("ResourceSearch")
  .props({})
  .extend(withEnvironment)
  .extend(withPitchStore)
  .extend(withPlaylistStore)
  .extend(withProgramStore)
  .extend(withGroupStore)
  .extend(withOrganizationStore)
  .extend(withUserStore)
  .actions((self) => ({
    searchPitches: flow(function* (searchRequest: SearchRequest) {
      const searchApi = new SearchApi(self.environment.api)
      const pitchSearchResult = yield* toGenerator(searchApi.searchPitches(searchRequest))
      const playlistPitches = pitchSearchResult.results
        .map((r) => r.playlistPitch)
        .filter((pp): pp is PitchWithPlaylistMetadata => pp !== undefined)
      const filter = searchRequest.filters.find((f) => f.type === SearchFilterType.WithinPlaylist)
      if (filter && playlistPitches.length) {
        const playlistId = filter.value as string
        self.playlistStore.putPlaylistPitches({
          playlistPitches: { [playlistId]: playlistPitches },
          appendOnly: true,
        })
      }

      return {
        results: self.pitchStore.putPitches(pitchSearchResult.results.map((r) => r.pitch)),
        totalCount: pitchSearchResult.totalCount,
        numPages: pitchSearchResult.numPages,
        pageNumber: pitchSearchResult.pageNumber,
      }
    }),

    searchStudioPitches: flow(function* (
      searchRequest: SearchRequest,
    ): Generator<Promise<unknown>, SearchResult<Pitch>, any> {
      const searchApi = new SearchApi(self.environment.api)
      const pitchSearchResult = yield* toGenerator(searchApi.searchStudioPitches(searchRequest))

      return {
        results: self.pitchStore.putStudioPitches(pitchSearchResult.results),
        totalCount: pitchSearchResult.totalCount,
        numPages: pitchSearchResult.numPages,
        pageNumber: pitchSearchResult.pageNumber,
      }
    }),

    searchPlaylistPitches: flow(function* (searchRequest: SearchRequest) {
      const searchApi = new SearchApi(self.environment.api)
      const pitchSearchResult = yield* toGenerator(searchApi.searchPlaylistPitches(searchRequest))
      const playlistPitches = pitchSearchResult.results
        .map((r) => r.playlistPitch)
        .filter((pp): pp is PitchWithPlaylistMetadata => pp !== undefined)
      const filter = searchRequest.filters.find((f) => f.type === SearchFilterType.WithinPlaylist)
      if (filter && playlistPitches.length) {
        const playlistId = filter.value as string
        self.playlistStore.putPlaylistPitches({
          playlistPitches: { [playlistId]: playlistPitches },
          appendOnly: true,
        })
      }

      return {
        results: self.pitchStore.putPitches(pitchSearchResult.results.map((r) => r.pitch)),
        totalCount: pitchSearchResult.totalCount,
        numPages: pitchSearchResult.numPages,
        pageNumber: pitchSearchResult.pageNumber,
      }
    }),

    searchPlaylists: flow(function* (request: SearchRequest) {
      const searchApi = new SearchApi(self.environment.api)

      const playlistSearchResult = yield* toGenerator(searchApi.searchPlaylists(request))

      return {
        results: self.playlistStore.putPlaylists(playlistSearchResult.results),
        totalCount: playlistSearchResult.totalCount,
        numPages: playlistSearchResult.numPages,
        pageNumber: playlistSearchResult.pageNumber,
      }
    }),

    searchSubmissionPlaylists: flow(function* (request: SearchRequest) {
      const searchApi = new SearchApi(self.environment.api)

      const playlistSearchResult = yield* toGenerator(searchApi.searchSubmissionPlaylists(request))

      return {
        results: self.playlistStore.putPlaylists(playlistSearchResult.results),
        totalCount: playlistSearchResult.totalCount,
        numPages: playlistSearchResult.numPages,
        pageNumber: playlistSearchResult.pageNumber,
      }
    }),

    searchPrograms: flow(function* (request: SearchRequest) {
      const searchApi = new SearchApi(self.environment.api)

      const programSearchResult = yield* toGenerator(searchApi.searchPrograms(request))

      return {
        results: self.programStore.putPrograms(programSearchResult.results),
        totalCount: programSearchResult.totalCount,
        numPages: programSearchResult.numPages,
        pageNumber: programSearchResult.pageNumber,
      }
    }),

    searchGroups: flow(function* (request: SearchRequest) {
      const searchApi = new SearchApi(self.environment.api)

      const result = yield* toGenerator(searchApi.searchGroups(request))

      self.organizationStore.putOrganizations(result.organizations)

      return {
        results: self.groupStore.putGroups(result.results),
        totalCount: result.totalCount,
        numPages: result.numPages,
        pageNumber: result.pageNumber,
      }
    }),

    searchGroupMembers: flow(function* (request: SearchRequest) {
      const searchApi = new SearchApi(self.environment.api)

      const result = yield* toGenerator(searchApi.searchGroupMembers(request))
      self.userStore.putUsers(result.users)

      return {
        results: result.results,
        totalCount: result.totalCount,
        numPages: result.numPages,
        pageNumber: result.pageNumber,
      } as SearchResult<GroupMembership>
    }),

    searchUsers: flow(function* (request: SearchRequest) {
      const searchApi = new SearchApi(self.environment.api)

      const result = yield* toGenerator(searchApi.searchUsers(request))

      return {
        results: self.userStore.putUsers(result.results),
        totalCount: result.totalCount,
        numPages: result.numPages,
        pageNumber: result.pageNumber,
      }
    }),

    searchReactions: flow(function* (request: SearchRequest) {
      const searchApi = new SearchApi(self.environment.api)

      const result = yield* toGenerator(searchApi.searchReactions(request))

      return {
        results: result.results,
        totalCount: result.totalCount,
        numPages: result.numPages,
        pageNumber: result.pageNumber,
      }
    }),
  }))

export type ResourceSearchStoreSnapshot = SnapshotOut<typeof ResourceSearchStoreModel>
export type ResourceSearchStore = Instance<typeof ResourceSearchStoreModel>

export const withResourceSearchStore = (self: IStateTreeNode) => ({
  views: {
    get resourceSearchStore(): ResourceSearchStore {
      return getParent<Instance<typeof RootStoreModel>>(self).resourceSearchStore
    },
  },
})
