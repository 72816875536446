import { Instance, types } from "mobx-state-tree"
import { StringEnum } from "../utils/string-enum-type"
import { OpenGraphData } from "./opengraph-data"
import { DateTime } from "../utils/datetime-type"

export enum AttachmentType {
  Gallery = "Gallery",
  Website = "Website",
  Resume = "Resume",
  Project = "Project",
  Description = "Description",
}

export enum AttachmentLabel {
  Agenda = "Agenda",
  Agreement = "Agreement",
  Announcement = "Announcement",
  Article = "Article",
  BlogPost = "BlogPost",
  Calendar = "Calendar",
  Description = "Description", // TODO: is this used?
  Details = "Details",
  Document = "Document",
  Donation = "Donation",
  Form = "Form",
  Gallery = "Gallery",
  Guidelines = "Guidelines",
  Idea = "Idea",
  Invitation = "Invitation",
  Letter = "Letter",
  Link = "Link",
  Listing = "Listing",
  OnePager = "OnePager",
  Portfolio = "Portfolio",
  Project = "Project",
  Proposal = "Proposal",
  Recording = "Recording",
  Report = "Report",
  Resume = "Resume",
  SlideDeck = "SlideDeck",
  Spreadsheet = "Spreadsheet",
  Summary = "Summary",
  Update = "Update",
  Website = "Website",
}

export enum AttachmentViewOrigin {
  Mobile = "Mobile",
  Web = "Web",
  MobileWeb = "MobileWeb",
}

export const ProjectAttachmentDataModel = types.maybe(
  types.model("ProjectAttachmentData").props({
    assetId: types.string,
    projectName: types.string,
  }),
)
export type ProjectAttachmentData = Instance<typeof ProjectAttachmentDataModel>

export const PhotoAttachmentDataModel = types.maybe(
  types.model("PhotoAttachmentData").props({
    assetId: types.string,
    caption: types.string,
    ordinal: types.number,
  }),
)
export type PhotoAttachment = Instance<typeof PhotoAttachmentDataModel>

export const GalleryAttachmentDataModel = types.maybe(
  types.model("GalleryAttachmentData").props({
    photos: types.array(PhotoAttachmentDataModel),
  }),
)
export type GalleryAttachmentData = Instance<typeof GalleryAttachmentDataModel>

export const WebsiteAttachmentDataModel = types.maybe(
  types.model("WebsiteAttachmentData").props({
    url: types.string,
    openGraphData: types.maybe(types.frozen<OpenGraphData>()),
  }),
)
export type WebsiteAttachmentData = Instance<typeof WebsiteAttachmentDataModel>

export const ResumeAttachmentDataModel = types.maybe(
  types.model("ResumeAttachmentData").props({
    assetId: types.string,
    type: types.string,
    name: types.string,
  }),
)
export type ResumeAttachmentData = Instance<typeof ResumeAttachmentDataModel>

export const EmptyAttachmentDataModel = types.maybe(types.model("EmptyAttachmentData").props({}))

/**
 * Attachments Model
 */
export const AttachmentModel = types.model("Attachment").props({
  id: types.string,
  userId: types.maybe(types.string),
  data: types.union(
    {
      dispatcher: (snapshot?: any) => {
        if (snapshot?.photos) {
          return GalleryAttachmentDataModel
        } else if (snapshot?.url) {
          return WebsiteAttachmentDataModel
        } else if (snapshot?.assetId && snapshot?.name) {
          return ResumeAttachmentDataModel
        } else if (snapshot?.assetId && snapshot?.projectName) {
          return ProjectAttachmentDataModel
        }
        return EmptyAttachmentDataModel
      },
      eager: true,
    },
    GalleryAttachmentDataModel,
    ProjectAttachmentDataModel,
    WebsiteAttachmentDataModel,
    ResumeAttachmentDataModel,
  ),
  type: types.maybe(StringEnum(AttachmentType)),
  label: types.maybe(StringEnum(AttachmentLabel)),
  lastViewedUtc: types.maybe(DateTime),
})

export type Attachment = Instance<typeof AttachmentModel>
