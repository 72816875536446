import { Instance, types, flow, toGenerator } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { AttachmentModel, AttachmentViewOrigin } from "../models/attachment"
import { AttachmentApi } from "../services/api/attachment-api"
import logger from "../logging/logger"

export const AttachmentStoreModel = types
  .model("AttachmentStore")
  .props({
    pitchAttachments: types.map(types.array(AttachmentModel)),
    // Description is part of the pitch model and not a separate
    // object like the other attachments
    viewedDescriptionPitchIds: types.array(types.string),
    // To track anonymous attachment views for badging, we need to store the attachment ids when
    // viewed on web
    viewedAttachmentIdsOnWeb: types.array(types.string),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    markAttachmentAsViewed(pitchId: string, attachmentId: string) {
      const attachments = self.pitchAttachments.get(pitchId)
      const attachment = attachments?.find((a) => a.id === attachmentId)
      if (attachment) {
        try {
          attachment.lastViewedUtc = new Date()
        } catch (error) {
          logger.logWarning("Failed to optimistically update lastViewedUtc:", error)
        }
      }
    },
    markAttachmentAsViewedOnWeb(attachmentId: string) {
      if (!self.viewedAttachmentIdsOnWeb.includes(attachmentId)) {
        self.viewedAttachmentIdsOnWeb.push(attachmentId)
      }
    },
  }))
  .actions((self) => ({
    fetchAttachments: flow(function* (pitchId: string) {
      const attachmentApi = new AttachmentApi(self.environment.api)
      const result = yield* toGenerator(
        attachmentApi.getAttachmentsForPitch({
          pitchId,
        }),
      )
      self.pitchAttachments.set(pitchId, result.attachments)
      return self.pitchAttachments.get(pitchId)
    }),
    fetchOpenGraphData: flow(function* (attachmentId: string) {
      const attachmentApi = new AttachmentApi(self.environment.api)
      const result = yield* toGenerator(attachmentApi.getOpenGraphData(attachmentId))
      return result
    }),
    fetchUrlPreview: flow(function* (url: string) {
      const attachmentApi = new AttachmentApi(self.environment.api)
      const result = yield* toGenerator(attachmentApi.getOpenGraphPreviewByUrl(url))
      return result
    }),
    markAsViewed: flow(function* (
      attachmentId: string,
      attachmentViewOrigin?: AttachmentViewOrigin,
    ) {
      const attachmentApi = new AttachmentApi(self.environment.api)
      yield attachmentApi.markAttachmentAsViewed({ attachmentId, attachmentViewOrigin })
    }),
    markPitchDescriptionAsViewed(pitchId: string) {
      if (!self.viewedDescriptionPitchIds.includes(pitchId)) {
        self.viewedDescriptionPitchIds.push(pitchId)
      }
    },
  }))
  .views((self) => ({
    getAttachments: (pitchId) => {
      return self.pitchAttachments.get(pitchId)
    },
  }))

export type AttachmentStoreType = Instance<typeof AttachmentStoreModel>
