import { Instance, types, flow, toGenerator } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { PitchApi } from "../services/api/pitch-api"
import { PlaylistApi } from "../services/api/playlist-api"
import { withPitchStore } from "./pitch-store"
import { withPlaylistStore } from "./playlist-store"
import { withContentPermissionStore } from "./content-permission-store"
import { ContentVisibility } from "../models/content-visibility"
import { Pitch, PitchModel } from "../models/pitch"
import { Playlist, PlaylistModel } from "../models/playlist"
import { withGroupStore } from "./group-store"
import { Group, GroupModel } from "../models/group"
import { SearchFilterType, SearchRequest } from "../services/api/search-api"
import { withResourceSearchStore } from "./resource-search-store"

export const PitchViewerStoreModel = types
  .model("PitchViewerStore")
  .props({
    subtitlesEnabled: types.optional(types.boolean, false),
    muteEnabled: types.optional(types.boolean, false),
    pitches: types.array(types.maybeNull(types.safeReference(PitchModel))),
    pitchIndex: types.optional(types.number, 0),
    playlist: types.maybe(types.safeReference(PlaylistModel)),
    group: types.maybe(types.safeReference(GroupModel)),
    playlistMostRecentlyAddedTo: types.safeReference(PlaylistModel),
    isReactionModalVisible: types.optional(types.boolean, false),
    isVideoStatsModalVisible: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .extend(withPitchStore)
  .extend(withPlaylistStore)
  .extend(withGroupStore)
  .extend(withContentPermissionStore)
  .extend(withResourceSearchStore)
  .actions((self) => ({
    showVideoStatsModal() {
      self.isVideoStatsModalVisible = true
    },
    hideVideoStatsModal() {
      self.isVideoStatsModalVisible = false
    },
    showReactionModal() {
      self.isReactionModalVisible = true
    },
    hideReactionModal() {
      self.isReactionModalVisible = false
    },
    setPlaylistMostRecentlyAddedTo(playlist: string) {
      self.playlistMostRecentlyAddedTo = playlist as any
    },
    disableSubtitles: async function () {
      self.subtitlesEnabled = false
    },
    enableSubtitles: async function () {
      self.subtitlesEnabled = true
    },
    enableAudio: async function () {
      self.muteEnabled = false
    },
    disableAudio: async function () {
      self.muteEnabled = true
    },
    fetchPlaybackUrl: async function (pitchId: string) {
      const api = new PitchApi(self.environment.api)
      const result = await api.getPlaybackUrl(pitchId)
      return result
    },
    fetchPlaybackTokens: async function (pitchId: string) {
      const api = new PitchApi(self.environment.api)
      const result = await api.getPlaybackTokens(pitchId)
      return result
    },
    reset() {
      self.pitches.replace([])
      self.pitchIndex = 0
      self.playlist = undefined
      self.group = undefined
    },
    setGroupProfilePitchesViewingData: ({
      pitchIndex,
      pitches,
      group,
    }: {
      pitchIndex: number
      pitches: Pitch[]
      group: Group
    }) => {
      const viewingData: {
        pitchIds: string[]
        pitchIndex: number
        groupId: string
      } = {
        pitchIds: pitches.map((p) => (p ? p.id : null)).filter((p): p is string => Boolean(p)),
        pitchIndex,
        groupId: group.id,
      }
      self.pitches.replace(viewingData.pitchIds as any)
      self.pitchIndex = pitchIndex
      self.group = viewingData?.groupId as any
      return viewingData
    },
    setViewingData: ({
      pitchIndex,
      pitches,
      playlist,
    }: {
      pitchIndex: number
      pitches: Pitch[]
      playlist?: Playlist
    }) => {
      const viewingData: {
        pitchIds: string[]
        pitchIndex: number
        playlistId?: string
        visibility?: ContentVisibility | string
        playlistPitchVisibility?: ContentVisibility | string
      } = {
        pitchIds: pitches.map((p) => (p ? p.id : null)).filter((p): p is string => Boolean(p)),
        pitchIndex,
      }
      if (playlist) {
        // this value gets serialized in getServerSideProps, which doesn't allow undefineds
        viewingData.playlistId = playlist.id
        viewingData.visibility = playlist.visibility
      }
      self.pitches.replace(viewingData.pitchIds as any)
      self.pitchIndex = pitchIndex
      self.playlist = viewingData?.playlistId as any
      return viewingData
    },
  }))
  .actions((self) => ({
    fetchSinglePitch: flow(function* (pitchId: string) {
      const existingPitch = self.pitchStore.pitches.get(pitchId)
      if (existingPitch) {
        // optimization: if the data already exists, start viewing it while we fetch updates
        self.setViewingData({
          pitches: [existingPitch],
          pitchIndex: 0,
          playlist: undefined,
        })
      }

      const pitchApi = new PitchApi(self.environment.api)
      const result = yield* toGenerator(
        pitchApi.getPitch({
          pitchId,
        }),
      )

      self.pitchStore.putPitches([result.pitch])
      return self.setViewingData({
        pitches: [result.pitch],
        pitchIndex: 0,
        playlist: undefined,
      })
    }),
    fetchPlaylist: flow(function* ({
      pitchId,
      playlistId,
    }: {
      pitchId?: string
      playlistId: string
    }) {
      const playlistApi = new PlaylistApi(self.environment.api)
      const playlistResult = yield* toGenerator(playlistApi.getPlaylist(playlistId))
      const playlist = self.playlistStore.putPlaylist(playlistResult.playlist)
      let pitches: Pitch[] = []
      if (
        self.contentPermissionStore.hasViewChildrenPermission(playlist.entity, playlist.visibility)
      ) {
        const pitchesResult = yield* toGenerator(playlistApi.getAllPitches(playlistId))
        pitches = self.pitchStore.putPitches(pitchesResult.pitches.map((p) => p.pitch))
        self.playlistStore.putPlaylistPitches({
          playlistPitches: { [playlistId]: pitchesResult.pitches },
          appendOnly: false,
        })
      }
      return self.setViewingData({
        pitches,
        pitchIndex: pitchId
          ? Math.max(
              0,
              pitches.findIndex((p) => p.id === pitchId),
            )
          : 0,
        playlist: playlistResult.playlist,
      })
    }),
    fetchGroupProfilePitches: flow(function* ({
      groupId,
      pitchId,
    }: {
      groupId: string
      pitchId?: string
    }) {
      const playlistApi = new PlaylistApi(self.environment.api)
      const group = yield* toGenerator(self.groupStore.fetchGroup(groupId))
      const pitchesResult = yield* toGenerator(playlistApi.getGroupMemberProfilePitches(groupId))
      const pitches = self.pitchStore.putPitches(pitchesResult.pitches.map((p) => p.pitch))
      self.playlistStore.putGroupProfilePitches(groupId, pitchesResult.pitches)
      const pitchIndex = pitchId ? pitches.findIndex((p) => p.id === pitchId) : 0
      return self.setGroupProfilePitchesViewingData({
        pitches,
        pitchIndex,
        group,
      })
    }),
    fetchGroupVideos: flow(function* ({ groupId, pitchId }: { groupId: string; pitchId?: string }) {
      const searchRequest: SearchRequest = {
        query: "",
        filters: [
          {
            type: SearchFilterType.ByGroup,
            value: groupId,
          },
        ],
        pageNumber: 1,
        pageSize: 100,
      }

      const result = yield* toGenerator(self.resourceSearchStore.searchPitches(searchRequest))
      const pitches = result.results

      const pitchExistsInTop100 = pitches.find((p) => p.id === pitchId)

      // If the pitch is not in the top 100, fetch it and add it to the top of the list
      if (pitchId && !pitchExistsInTop100) {
        const pitch = yield* toGenerator(self.pitchStore.fetchPitch(pitchId))
        pitches.unshift(pitch)
        pitches.pop()
      }
      const pitchIndex = pitchId ? pitches.findIndex((p) => p.id === pitchId) : 0

      return self.setViewingData({
        pitches,
        pitchIndex: Math.max(0, pitchIndex),
        playlist: undefined,
      })
    }),
    fetchUserActivityFeedPitches: flow(function* ({ activityId }: { activityId: string }) {
      const playlistApi = new PlaylistApi(self.environment.api)
      const pitchesResult = yield* toGenerator(playlistApi.getUserActivityFeedPitches(activityId))
      const pitches = self.pitchStore.putPitches(pitchesResult.pitches.map((p) => p.pitch))

      return self.setViewingData({
        pitches,
        pitchIndex: 0,
        playlist: undefined,
      })
    }),
    fetchGroupWallPosts: flow(function* ({ postId }: { postId: string }) {
      const playlistApi = new PlaylistApi(self.environment.api)
      const pitchesResult = yield* toGenerator(playlistApi.getGroupWallPosts(postId))
      const pitches = self.pitchStore.putPitches(pitchesResult.pitches.map((p) => p.pitch))
      return self.setViewingData({
        pitches,
        pitchIndex: 0,
        playlist: undefined,
      })
    }),
  }))

  .views((self) => ({
    get hasNextPitch() {
      return self.pitchIndex + 1 < self.pitches.length
    },
    get hasPreviousPitch() {
      return self.pitchIndex > 0
    },
  }))
  .views((self) => ({
    get nextPitchId() {
      if (!self.hasNextPitch) {
        return undefined
      }
      return self.pitches[self.pitchIndex + 1]?.id
    },
    get previousPitchId() {
      if (!self.hasPreviousPitch) {
        return undefined
      }
      return self.pitches[self.pitchIndex - 1]?.id
    },
  }))
  .actions((self) => ({
    setPitchIndex: (pitchIndex) => {
      self.pitchIndex = pitchIndex
    },
    selectPitch: (pitchId) => {
      const pitchIndex = self.pitches.findIndex((pitch) => pitch != null && pitch.id === pitchId)
      if (pitchIndex >= 0) {
        self.pitchIndex = pitchIndex
      }

      return pitchIndex
    },
  }))

export type PitchViewerStore = Instance<typeof PitchViewerStoreModel>
