import { Instance, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withSessionStore } from "./session-store"
import { isAfter, add } from "date-fns"

const DAYS_BETWEEN_PROFILE_PITCH_NUDGE = 2

export const UserBehaviorStoreModel = types
  .model("UserBehaviorStore")
  .props({
    lastSeenProfilePitchNudge: types.optional(types.Date, Date.now()),
    loginDate: types.maybe(types.Date),
  })
  .extend(withEnvironment)
  .extend(withSessionStore)
  .actions((self) => ({
    setProfilePitchNudgeSeen: () => {
      self.lastSeenProfilePitchNudge = new Date()
    },
  }))
  .views((self) => ({
    canGetProfilePitchNudge() {
      return (
        !self.lastSeenProfilePitchNudge ||
        isAfter(
          new Date(),
          add(self.lastSeenProfilePitchNudge, { days: DAYS_BETWEEN_PROFILE_PITCH_NUDGE }),
        )
      )
    },
  }))
export type UserBehaviorStore = Instance<typeof UserBehaviorStoreModel>
