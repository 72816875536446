import "../styles/globals.css"
import type { AppProps } from "next/app"
import type { Page } from "../types/page"
import React, { ReactNode, useEffect, useState } from "react"
import { registerCustomValidators } from "pitch45-common/utils/custom-validators"
import { theme } from "pitch45-common/theme/theme"
import * as Sentry from "@sentry/nextjs"
import TopLevelErrorPage from "./top-level-error"
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles"
import { getMergedTheme } from "../theme/muiTheme"
import SplitSdk from "../services/features/splitio"
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query"
import Head from "next/head"
import makeInspectable from "mobx-devtools-mst"
import { ToastProvider } from "../ui/toast/toast-provider"
import { createWebEnvironment } from "../utils/create-web-environment"
import {
  ApiError,
  GeneralApiProblem,
  logResponseError,
} from "pitch45-common/services/api/api-problem"
import logger from "pitch45-common/logging/logger"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import MaintenancePage from "./maintenance"
import { useRouter } from "next/router"
import { RootStore, RootStoreModel } from "pitch45-common/stores/root-store"
import { RootStoreProvider, useStores } from "pitch45-common/stores/root-store-context"
import { DefaultTreatment, WebFeatureFlags } from "pitch45-common/stores/feature-flag-store"
import { LogRocketLogger } from "../services/logging/logrocket"
import { observer } from "mobx-react-lite"
import WebOAuthProvider from "../services/authentication/oauth"
import GoogleTagManager from "../components/google-tag-manager/google-tag-manager"

type AppWithLayoutProps = AppProps & {
  Component: Page
}

// Detecting API availability is trickier on Web since there is a preflight request that we can't
// handle if our servers are down, so at this point we are just lumping all network issues together
const isMaintenanceModeError = (error: any) =>
  (error instanceof ApiError &&
    error.problem.kind === "cannot-connect" &&
    window?.navigator.onLine) ||
  false

const topLevelErrorFallback = ({ error }) => {
  if (isMaintenanceModeError(error)) {
    return <MaintenancePage error={error} />
  }
  return <TopLevelErrorPage error={error} />
}

registerCustomValidators()

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(error: any, query) {
      if (error.response) {
        logResponseError(error)
      } else {
        logger.logError(error, { queryKey: query.queryKey })
      }
    },
  }),
  mutationCache: new MutationCache({
    onError(error: any) {
      if (error.response) {
        logResponseError(error)
      } else {
        logger.logError(error)
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      structuralSharing: false,
      staleTime: 300,
      retryDelay: (attemptIndex) => Math.min(1000 * 3 ** attemptIndex, 30000),
      retry: (failureCount, error) => {
        const problem = (error as any).problem as GeneralApiProblem
        if ((problem as any)?.temporary && failureCount <= 2) {
          return true
        } else {
          return false
        }
      },
      // useErrorBoundary is not supported in react-query v5
      useErrorBoundary: (error) => {
        // forward 502 errors to the error boundary
        return isMaintenanceModeError(error)
      },
    },
  },
  // custom logger is deprecated. These noops are for suppressing duplicate logs.
  logger: {
    log: () => {},
    warn: () => {},
    error: () => {},
  },
})

const oauthProvider = new WebOAuthProvider()
const env = createWebEnvironment(queryClient, oauthProvider)
env.setup()

function MyApp({ Component, pageProps }: AppWithLayoutProps) {
  // disable prefetch because it makes way, way more requests for pages than the user is ever likely to click
  const router = useRouter()
  useEffect(() => {
    router.prefetch = async () => {}
  }, [router])

  const [rootStore] = useState<RootStore>(() => RootStoreModel.create({}, env))

  useEffect(() => {
    oauthProvider.onSilentRenew = (token) => {
      if (token) {
        rootStore.sessionStore.setAuthorization(token, undefined)
      } else {
        // TODO do we want to logout or do we want to prompt the user that their session
        // has expired and they need to refresh?
        rootStore.sessionStore.logout()
      }
    }

    rootStore.featureFlagStore.setSplitFactory(SplitSdk)

    // enable dev tools, see https://github.com/mobxjs/mobx-devtools/blob/master/README.md#mobx-state-tree
    if (process.env.NODE_ENV === "development") {
      makeInspectable(rootStore)
    }
  }, [])

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page: ReactNode) => page)
  const mergedTheme = getMergedTheme(theme.pitch45Dark)
  return (
    <>
      <Head>
        <title>Loop :45</title>
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <MuiThemeProvider theme={mergedTheme}>
        <Sentry.ErrorBoundary fallback={topLevelErrorFallback}>
          <QueryClientProvider client={queryClient}>
            <RootStoreProvider value={rootStore}>
              <LogRocket />
              <GoogleTagManager />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ToastProvider>{getLayout(<Component {...pageProps} />)}</ToastProvider>
              </LocalizationProvider>
            </RootStoreProvider>
          </QueryClientProvider>
        </Sentry.ErrorBoundary>
      </MuiThemeProvider>
    </>
  )
}

const LogRocket = observer(function LogRocket() {
  const { featureFlagStore, sessionStore } = useStores()
  const isLogRocketEnabled =
    featureFlagStore.getWebFlagValue(WebFeatureFlags.WebLogRocketEnabled) === DefaultTreatment.On
  useEffect(() => {
    if (isLogRocketEnabled) {
      const logRocketLogger = new LogRocketLogger()
      logger.addLogger("logrocket", logRocketLogger)

      // make sure to identify in case this effect runs after the session is set (may happen if feature flags are slow to load)
      logRocketLogger.identify(sessionStore.session?.userId)
    }
  }, [isLogRocketEnabled])

  return null
})

export default MyApp
